import { type AxiosResponse, isAxiosError } from "axios";

import type {
  FrontendApi,
  SettingsFlow,
  RecoveryFlow,
  RegistrationFlow,
  LoginFlow,
  VerificationFlow,
  ContinueWithSettingsUiFlow,
  ContinueWithVerificationUiFlow,
  ContinueWithRecoveryUiFlow
} from "@ory/client";

import type { UseSdkErrorOptions } from "~/plugins/01.ory-sdk";

// LogoutFlow is excluded, since it doesn't have an id
type OryFlow =
  | SettingsFlow
  | RecoveryFlow
  | RegistrationFlow
  | LoginFlow
  | VerificationFlow
  | ContinueWithSettingsUiFlow
  | ContinueWithVerificationUiFlow
  | ContinueWithRecoveryUiFlow;

export type ProceedWithFlowCallback<T> = (
  sdk: FrontendApi,
  flow: T | undefined
) => Promise<AxiosResponse<T> | undefined> | Promise<void>;

type UseOryFlowOptions<T extends OryFlow> = {
  createFlow: (sdk: FrontendApi) => Promise<AxiosResponse<T>>;
  getFlow?: (sdk: FrontendApi, requestParameters: { id: string }) => Promise<AxiosResponse<T>>;
  flowIdInUrl?: boolean;
  errorHandling?: UseSdkErrorOptions;
};

export function useOryFlow<T extends OryFlow>({
  createFlow,
  getFlow,
  flowIdInUrl = !!getFlow,
  errorHandling: errorHandlingOptions
}: UseOryFlowOptions<T>) {
  const flow = ref<T>();

  const router = useRouter();
  const route = useRoute();
  const { $ory } = useNuxtApp();
  const { sdk, useSdkError } = $ory;

  async function getFlowWithErrorHandling(flowId: string) {
    if (!getFlow) {
      return;
    }

    try {
      const response = await getFlow(sdk, { id: flowId });
      flow.value = response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        sdkErrorHandler(error);
      }
    }
  }

  async function createFlowWithErrorHandling(returnTo: string | undefined) {
    try {
      const response = await createFlow(sdk);
      if (flowIdInUrl) {
        router.replace({ query: { flow: response.data.id, return_to: returnTo } });
      }
      flow.value = response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        sdkErrorHandler(error);
      }
    }
  }

  const sdkErrorHandler = useSdkError(
    getFlowWithErrorHandling,
    (value) => {
      flow.value = value as T;
    },
    errorHandlingOptions
  );

  const proceedWithFlow = async (callback: ProceedWithFlowCallback<T>) => {
    try {
      const response = await callback(sdk, flow.value);
      if (response) {
        flow.value = response.data;
      }
    } catch (error) {
      if (isAxiosError(error)) {
        sdkErrorHandler(error);
      }
    }
  };

  onMounted(async () => {
    const flowId = (route.query.flow ?? "") as string;
    const returnTo = (route.query.return_to ?? "") as string;
    if (!getFlow || !flowId) {
      await createFlowWithErrorHandling(returnTo);
    } else {
      try {
        await getFlowWithErrorHandling(flowId);
      } catch {
        await createFlowWithErrorHandling(returnTo);
      }
    }
  });

  return { flow, restartFlow: createFlowWithErrorHandling, proceedWithFlow, sdkErrorHandler };
}
